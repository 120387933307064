<template>
  <div class="fast-btn-setting" v-loading="loading">
    <div class="setting-left">
      <div class="setting-left-item" v-for="group of groupData" :key="group.label">
        <div class="setting-left-item-top">
          <div class="setting-left-item-top-title">{{ group.label }}</div>
          <el-button type="primary" size="small" @click="handleAddBtn(group)">添加按钮</el-button>
        </div>
        <div class="setting-left-item-wrapper">
            <div class="setting-left-item-wrapper-left">
              <Draggable
                v-model="group.leftBtns"
                :animation="300"
                class="buttons-draggable"
                :group="`btns-group${group.label}`"
                @sort="onSortChange(group)"
              >
                <el-button
                  v-for="btn of group.leftBtns"
                  :key="btn.id"
                  round
                  :icon="btn.start_time > 0 ? 'el-icon-time' : ''"
                  size="small"
                  :class="{'active-btn': btn.id === formModel.id}"
                  @click="handleClickBtn(btn)"
                >
                  {{ btn.btn_name }}
                  <i
                    v-if="isCustomBtn(btn.btn_type)"
                    class="el-icon-close el-icon--right"
                    @click="handleDeleteBtn(btn.id)"
                  />
                </el-button>
              </Draggable>
            </div>
            <div class="setting-left-item-wrapper-right" v-if="group.btn_data?.length > 3">
              <div class="setting-left-item-wrapper-right-more">
                <div>更多</div>
              </div>
              <div class="setting-left-item-wrapper-right-box">
                <Draggable
                  v-model="group.rightBtns"
                  :animation="300"
                  class="buttons-draggable"
                  :group="`btns-group${group.label}`"
                  @sort="onSortChange(group)"
                >
                  <el-button
                    v-for="btn of group.rightBtns"
                    :key="btn.id"
                    round
                    :icon="btn.start_time > 0 ? 'el-icon-time' : ''"
                    size="small"
                    :class="{'active-btn': btn.id === formModel.id}"
                    @click="handleClickBtn(btn)"
                  >
                    {{ btn.btn_name }}
                    <i
                      v-if="isCustomBtn(btn.btn_type)"
                      class="el-icon-close el-icon--right"
                      @click="handleDeleteBtn(btn.id)"
                    />
                  </el-button>
                </Draggable>
              </div>
            </div>
        </div>
      </div>
    </div>
    <div class="setting-right">
      <div class="setting-right-top">
          <div class="setting-right-top-title">
            {{ formModel.id ? '编辑按钮' : '添加按钮'}}
          </div>
          <el-button type="primary" size="small" @click="handleSave">保 存</el-button>
      </div>
      <div class="setting-right-wrapper">
        <el-form ref="editBtnForm" :model="formModel" label-width="100px" :rules="formRules">
          <el-form-item label="按钮名称" prop="btn_name">
            <el-input type="text" v-model="formModel.btn_name" maxlength="10" show-word-limit />
          </el-form-item>
          <el-form-item label="按钮展示时间" v-if="isCustomBtn(formModel.btn_type)">
            <el-date-picker
              v-model="formModel.btn_show_time"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="timestamp">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="高亮展示">
            <el-switch v-model="formModel.is_highlight"></el-switch>
          </el-form-item>
          <el-form-item label="按钮路径" prop="btn_path" v-if="isCustomBtn(formModel.btn_type)">
            <el-tag @click="onPickChange">{{ getLabelName(this.localValue) }}</el-tag>
          </el-form-item>
          <el-form-item label="适用商品" v-if="isCustomBtn(formModel.btn_type)">
            <SkuSelector
              v-loading="skuLoading"
              class="btn-sku-selector"
              :data="skuList"
              @change="handleSelectSku"
            />
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
  import Draggable from "vuedraggable"
  import SkuSelector from '@/components/sp-sku-select-box'
  import { LINK_PATH } from '@/consts'
  import { mapGetters } from 'vuex'
  import { getBtnList, createBtn, updateBtn, updateBtnSort, getGoodsList, deleteBtn } from './api'

  export default {
    components: { Draggable, SkuSelector },
    data () {
      return {
        loading: false,
        skuLoading: false,
        BtnTypeEnum: {
          CUSTOM: 'custom'
        },
        btnType: {
          cancel_order: '取消订单',
          invoicing: '开具发票',
          logistics_view: '查看物流',
          apply_after_sales: '申请售后',
          sell_order: '卖了换钱',
          custom: '自定义按钮',
        },
        groupData: [],
        formModel: {
          id: '',
          order_status: '',
          btn_name: '',
          btn_type: '',
          btn_show_time: undefined,
          is_highlight: false,
          btn_path: '',
          btn_rel_item: '',
        },
        formRules: {
          btn_name: [
            { required: true, message: '请输入按钮名称', trigger: 'change' },
          ],
          btn_path: [
            { required: true, message: '请选择按钮路径', trigger: 'change' },
          ]
        },
        localValue: {},
        skuList: [],
      }
    },
    async mounted() {
      try {
        this.loading = true
        await this.init()
      } finally {
        this.loading = false
      }
    },
    computed: {
      ...mapGetters([
        'wxapp_id',
        'template_name'
      ])
    },
    methods: {
      async init() {
        const res = await getBtnList()
        this.groupData = res.data.data
        if(!this.groupData?.length) return
        this.groupData.forEach((group) => {
          if(group.btn_data?.length) {
            group.leftBtns = group.btn_data.slice(0, 3)
            group.rightBtns = group.btn_data.slice(3)
          }
        })
        for (const group of this.groupData) {
          if(this.formModel.id) return
          // 设置初始化默认值
          if(group.btn_data?.length) {
            const currentBtn = group.btn_data[0]
            this.setCurrentBtnInfo(currentBtn)
            return
          }
        }
      },
      // 详情回显 根据id查询商品数据
      async getGoodsDataByid(ids) {
        try {
          this.skuLoading = true
          const res = await getGoodsList(ids)
          this.skuList = res?.data?.data?.list
        } finally {
          this.skuLoading = false
        }
      },
      /** 自定义按钮 */
      isCustomBtn (btn_type) {
        return btn_type === this.BtnTypeEnum.CUSTOM
      },
      async onSortChange(group) {
        group.btn_data = [...group.leftBtns , ...group.rightBtns]
        try {
          this.loading = true
          await updateBtnSort(group.btn_data)
          this.$message.success('排序成功')
        } finally {
          await this.init()
          this.loading = false
        }
      },
      /** 设置当前按钮信息 */
      async setCurrentBtnInfo(currentBtn) {
        this.formModel = {
          ...currentBtn
        }
        if(currentBtn.start_time > 0) {
          this.formModel.btn_show_time = [currentBtn.start_time* 1000, currentBtn.end_time * 1000]
        }
        this.localValue = JSON.parse(currentBtn.btn_path || '{}')
        if(currentBtn.btn_rel_item?.length) {
          await this.getGoodsDataByid(currentBtn.btn_rel_item)
        }
      },
      async handleClickBtn(currentBtn) {
        if(this.formModel.id === currentBtn.id) return
        this.setCurrentBtnInfo(currentBtn)
      },
      /** 删除按钮 */
      async handleDeleteBtn(id) {
        this.$confirm('确认删除该自定义按钮？').then(async _ => {
          try {
            this.loading = true
            await deleteBtn(id)
            this.$message.success('删除成功')
            this.formModel.id = ''
            this.init()
          } finally {
            this.loading = false
          }
        })
      },
      /** 添加按钮 */
      async handleAddBtn(group) {
        this.formModel= {
          id: '',
          order_status: group.order_status,
          btn_name: '按钮',
          btn_type: this.BtnTypeEnum.CUSTOM,
          btn_show_time: undefined,
          is_highlight: false,
          btn_path: '',
          btn_rel_item: '',
        }
        this.skuList = []
        this.localValue = {}
      },
      handleSelectSku (data) {
        const ids = data?.map(item => item.item_id)
        this.formModel.btn_rel_item = ids
      },
      async onPickChange () {
        const { linkPage, id } = this.localValue
        const res = await this.$picker.path({
          data: id,
          tab: linkPage,
          multiple: false,
          wxapp_id: this.wxapp_id,
          template_name: this.template_name,
          isDouyin: false,
          isHotWords: true
        })
        this.localValue = {
          ...res,
          linkType: 0,
          linkUrl: '',
          video_cover: '',
          CopyUrl: "",
          CopyTitle: "",
          autoplay: false,
          hotzoneType: 'link'
        }
        this.formModel.btn_path = JSON.stringify(this.localValue)
      },
      getLabelName(value) {
        const { linkPage, title, id } = value
        if (linkPage) {
          return `${LINK_PATH[linkPage]}：${id ? '[' + id + ']' : ''} ${title}`
        } else {
          return '选择路径'
        }
      },
      async handleSave() {
        await this.$refs['editBtnForm'].validate()
        const data = {
          ...this.formModel
        }
        if(this.formModel.btn_show_time) {
          data.start_time = this.formModel.btn_show_time[0] / 1000
          data.end_time = this.formModel.btn_show_time[1] / 1000
          delete data.btn_show_time
        }
        try {
          this.loading = true
          if(!this.formModel.id) {
            await createBtn(data)
          } else {
            await updateBtn(data)
          }
          this.$message.success('保存成功')
          this.init()
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>

<style lang="scss">
@media screen and (min-width: 1600px) {
  .fast-btn-setting {
    .el-col-lg-6 {
      width: 45% !important;
    }
  }
}
@media screen and (min-width: 960px) and (max-width:1600px) {
  .fast-btn-setting {
    .el-col-lg-6 {
      width: 100% !important;
    }
    .btn-sku-selector > div:first-child{
      height: 220px !important;
    }
  }
}
</style>

<style scoped lang="scss">
@import './index.scss'
</style>
